import * as React from 'react';

import sesameLogo from '../assets/images/sesame-logo.svg';

import SVG from 'react-inlinesvg';
// import SesameLogoSVG from '-!svg-react-loader!../assets/images/sesame-logo.svg';
// import { ReactComponent as SesameLogoSVG } from '../assets/images/sesame-logo.svg';

const SesameLogo = (props: React.HTMLAttributes<HTMLImageElement>) =>  (
   <SVG src={sesameLogo} alt="Sesame Logo" width="240px"
    classame="uk-margin-left uk-margin-botton"/>
/*
    <img src={sesameLogo} alt="sesame logo" width="240px"
      className="uk-margin-left uk-margin-bottom"/>
    */
);

export default SesameLogo;
