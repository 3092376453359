import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
// import './App.css';
// import '/css/uikit.theme.css';
import SlackLoginBox from './pages/SlackLoginBox';
import PageHeader from './widgets/pageHeader';

interface IProps {
  location: {
    search: string;
  };
}

function App() {
  return (
    <div>
      <div >
        <PageHeader />
      </div>
      <div>
        <SlackLoginBox />
      </div>
    </div>
  );
}

export default App;
