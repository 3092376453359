// import queryString from 'query-string';
import Cookies from 'js-cookie';
import React, { Component, MouseEvent, useEffect, useState } from 'react';
// import { RouteComponentProps } from 'react-router';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import API from '../util/API';

const EMAIL_COOKIE_NAME = 'loginEmail';

type channelType = 'slack' | 'email';

interface IState {
  authenticated: boolean;
  callBackUrl: string;
  email: string;
  enteredEmail: string;
  enteredSlackKey: string;
  slackKey: string;
}

interface IAuthBySlackRequest {
  email: string;
  displayName: string;
  callBackUrl: string;
}
interface IAuthBySlackConfirm {
  email: string;
  slackKey: string;
}

export default function SlackLoginBox() {
  return (
    <Router>
      <SlackBoxContent />
    </Router>
  );
}

async function sendCodeApi(
  payload: IAuthBySlackRequest,
  channel: channelType,
  cb: (err: any, res: any) => void) {

  const command = channel === 'slack' ? 'loginslack' : 'loginemail';
  API.post(undefined, `/api/v1/auth/${command}`, payload)
    .then((response) => {
      cb(null, response);
    })
    .catch((error) => {
      cb(error, null);
    });
}

async function slackConfirmApi(
  payload: IAuthBySlackConfirm,
  cb: (err: any, res: any) => void) {
  API.post(undefined, '/api/v1/auth/authconfirmslack', payload)
    .then((response) => {
      cb(null, response);
    })
    .catch((error) => {
      cb(error, null);
    });
}

function SlackBoxContent() {
  const [callBack, setCallBack] = useState(getCallBackUrl());
  const [email, setEmail] = useState(Cookies.get(EMAIL_COOKIE_NAME) || '');
  const [channel, setChannel] = useState<'slack' | 'email'>('slack');
  const [accountExists, setAccountExists] = useState(false);
  const [slackKey, setSlackKey] = useState('');

  const validEmail = email.match(/..*@..*\...*/) !== null;

  const sendCode = (newEmail: string, reqChannel: channelType, w: Window) => {
    setChannel(reqChannel);
    setEmail(newEmail);
    sendCodeApi({
      callBackUrl: callBack || '', // we know this isn't null here..
      displayName: '',
      email: newEmail,
    },
      reqChannel,
      (err, res) => {
        if (err) {
          (w as any).UIkit.notification(err.message, { status: 'danger' });
        } else {
          console.log('setting cookie');
          Cookies.set(EMAIL_COOKIE_NAME, email, { expires: 365, sameSite: 'Lax' });
          setAccountExists(true);
        }
      });
  };

  const codeSubmit = (newSlackKey: string, w: Window) => {
    setSlackKey(newSlackKey);
    slackConfirmApi({
      email,
      slackKey: newSlackKey,
    }, (err, res) => {
      if (err) {
        (w as any).UIkit.notification(err.message, { status: 'danger' });
      } else {
        // On success, we should have gotten a 302 and thus sayanora...
        (w as any).UIkit.notification('You should be redirected to your site', { status: 'ok' });
      }
    });
  };

  if (!callBack) {
    return (
      <Router>
        <div className="uk-container uk-width-5-6@m uk-card-body">
          <LoginErrorSection err="No Callback URL specified" />
        </div>
      </Router>
    );
  } else {
    return (
      <Router>
        <div className="uk-container uk-width-1-1">
          <div className="uk-card uk-card-default uk-card-body">
            <LoginEmail submit={sendCode} />
            <LoginSlackKey submit={codeSubmit} channel={channel} accountExists={accountExists} />
            <SuccessMessage message="" />
          </div>
        </div>
      </Router>
    );
  }
}

function LoginErrorSection(props: { err: string; }) {
  return (
    <div className="uk-container uk-width-1-2">
      <div className="uk-card uk-card-default uk-card-body uk-width-1-3>">
        <h3 className="uk-card-title uk-text-left">Error</h3>
        <p>{props.err}</p>
      </div>
    </div>
  );
}

interface ILoginEmailProps {
  submit: (code: string, channel: channelType, w: Window) => void
}

function LoginEmail({ submit }: ILoginEmailProps) {
  const [email, setEmail] = useState(Cookies.get(EMAIL_COOKIE_NAME) || '');
  const [displayName, setDisplayName] = useState('');
  const [validEmail, setValidEmail] = useState(false);

  useEffect(() => {
    setValidEmail(email.match(/..*@.*\...*/) !== null);
  }, [email]);

  const onClickHandler = (channel: channelType, w: Window) => {
    submit(email.trim(), channel, w);
  };

  return (
    <div>
      <div>
        <h3 className="uk-card-title uk-text-left">Login</h3>
      </div>
      <div className="uk-container uk-margin-bottom">
        <p className="uk-text-left">Enter your email address and we'll send you a code.</p>
        <div className="uk-grid">
          <div>
            <input
              id="emailInput"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value.trim())}
              placeholder="Enter Email"
              className="uk-input uk-width-1-1 uk-margin-bottom"
            />
          </div>
          <div>
            <div>
              <button
                className="uk-button uk-button-primary uk-margin-right uk-width-1-1"
                onClick={() => { onClickHandler('slack', window); }}
                disabled={!validEmail}
              >
                Get Code from Slack
        </button>
            </div>
            {/*
            <div>
              <button
                className="uk-button uk-button-primary uk-margin-top uk-width-1-1"
                onClick={() => { onClickHandler('email', window); }}
                disabled={!validEmail}
              >
                Get Code from Email
        </button>
            </div>
            */}
          </div>
        </div>
      </div>
    </div >

  );
}

//            disabled={this.state.slackKey.length === 0}
//           onClick={this.slackKeySubmit}>
//            onChange={this.slackKeyOnChange}
//           { this.state.enteredSlackKey && <span data-uk-spinner=""></span>}
interface ILoginChannelProps {
  submit: (a: string, b: Window) => void;
  channel: channelType;
  accountExists: boolean;
}
function LoginSlackKey({ submit, channel, accountExists }: ILoginChannelProps) {

  if (!accountExists) {
    return (<div></div>);
  }

  const [slackKey, setSlackKey] = useState('');
  const [validKey, setValidKey] = useState(false);

  useEffect(() => {
    setValidKey(slackKey.length === 10);
  }, [slackKey]);

  let message = 'We\'ve sent a code to you as "sesame-auth" in slack.  Enter it here';
  if (channel == 'email') {
    message = 'Check your email for a code and enter it here';
  }

  return (
    <div className="uk-algin-left">
      <div>

        <p>{message}</p>
      </div>
      <div className="uk-container">
        <input
          id="slackKeyInput"
          type="text"
          value={slackKey}
          onChange={(e) => setSlackKey(e.target.value.trim())}
          placeholder="Code from Slack"
          className="uk-input uk-width-1-2 uk-align-left"
        />
        <button
          className="uk-button uk-button-primary"
          onClick={() => { submit(slackKey, window); }}
        >
          Log in
          </button>
      </div>
    </div >
  );
}

function SuccessMessage(props: { message: string }) {
  return (
    <div>{props.message}</div>
  );
}

function getCallBackUrl() {
  const query = new URLSearchParams(useLocation().search);
  return query.get('callBackUrl');
}
