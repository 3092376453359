import React from 'react';
// import UIKit from 'uikit';
import SesameLogo from '../components/sesameLogo';

// const margin = UIKit.margin('#margin', {})

const PageHeader = (props: React.HTMLAttributes<HTMLElement>) => (
  <div
    className="uk-background-default uk-flex uk-flex-left ">
    <h2 className="uk-margin-remove-bottom uk-padding-small">
      <SesameLogo />
      <span className="uk-margin-left">
        Sesame Authentication
        </span>
    </h2>
  </div>
);

export default PageHeader;
